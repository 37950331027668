import { REG_EXP } from '@profilum-collections/reg-exp';

export function isPhoneValid(phoneString: string): boolean {
  return phoneString && REG_EXP.fullPhoneRegExp.test(phoneString) && phoneString.length === 11;
}

export function clearPhone(phoneString: string): string {
  return phoneString.replace(/\D/g, '');
}

export function getPastedClearPhone(event: ClipboardEvent): string {
  let result = clearPhone((event.clipboardData || (<any>window).clipboardData).getData('text'));
  if (result.length === 11) {
    result = result.slice(1);
  }
  return result;
}

export function compareByJSON(a: any, b: any): boolean {
  return JSON.stringify(a, nullToEmptyString) === JSON.stringify(b, nullToEmptyString);
}

export const nullToEmptyString = (_key: string, value: any) => (value === null ? '' : value);

export function debounce(func: () => void, ms: number): () => void {
  let timeout: number;

  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(), ms);
  };
}
